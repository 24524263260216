import { useState, useEffect } from "react";
import {
  ChevronDown,
  ChevronUp,
  Search,
  FolderLock,
  FolderOpen,
  Folder,
  Users2,
} from "lucide-react";

import { getChatHistory } from "../../service/chat";
import { groupMessagesByDate } from "./utils";
import { useLocation, useNavigate } from "react-router-dom";

const GroupHistoryItem = ({
  category,
  messages,
  handleSelectChat,
  selectedMessage,
}) => {
  return (
    <div className="mt-4">
      <div className="text-sm font-medium mb-2" style={{ fontSize: "14px" }}>
        {category}
      </div>
      {messages &&
        messages.length > 0 &&
        messages.map((message, i) => (
          <HistoryItem
            key={i}
            title={message.user_message}
            isActive={`${category}_${i}` === selectedMessage}
            onClick={() => handleSelectChat(category, i)}
          />
        ))}
    </div>
  );
};

const HistoryItem = ({ title, isActive, onClick }) => {
  return (
    <div
      className={`-mx-4 px-4 py-2 cursor-pointer rounded ${
        isActive ? "bg-blue-50 text-blue-600" : "hover:bg-gray-50"
      }`}
      onClick={onClick}
    >
      <div
        className={`truncate ${
          isActive ? " text-blue-600" : "hover:bg-gray-50 text-secondary"
        }`}
      >
        {title}
      </div>
    </div>
  );
};

const WorkStreamItem = ({
  icon: Icon,
  title,
  children,
  defaultExpanded = false,
  isActive,
  onClick,
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  return (
    <div>
      <div
        className={`flex items-center px-4 py-2 cursor-pointer text-secondary rounded hover:bg-gray-50 ${
          isActive ? "bg-blue-50 text-blue-600" : ""
        }`}
        onClick={() => {
          if (children) {
            setIsExpanded(!isExpanded);
          } else {
            onClick();
          }
        }}
      >
        {Icon && <Icon className="w-4 h-4 mr-2 text-gray-500 flex-shrink-0" />}
        <span className="flex-1 truncate text-sm">{title}</span>
        {children && (
          <ChevronDown
            className={`w-4 h-4 transition-transform flex-shrink-0 ${
              isExpanded ? "rotate-180" : ""
            }`}
          />
        )}
      </div>
      {isExpanded && children && (
        <div className={`ml-4 border-gray-200 text-secondary`}>{children}</div>
      )}
    </div>
  );
};

export default function ChatHistorySidebar() {
  const navigate = useNavigate();
  const location = useLocation();

  const [isHistoryExpanded, setIsHistoryExpanded] = useState(true);
  const [isWorkStreamsExpanded, setIsWorkStreamsExpanded] = useState(true);
  const [activeItem, setActiveItem] = useState("Cash Revenue Project Alpha");
  const [groupedMessages, setGroupedMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState();

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };

  const fetchChatHistory = async () => {
    try {
      const messages = await getChatHistory();
      if (messages) {
        const groupedMessages = groupMessagesByDate(messages);
        setGroupedMessages(groupedMessages);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchChatHistory();
  }, []);

  const handleSelectChat = (category, index) => {
    setSelectedMessage(`${category}_${index}`);
    const messages = groupedMessages[category];
    const formattedChat = [
      {
        position: "right",
        text: messages[index].user_message,
      },
      {
        position: "left",
        text: messages[index].bot_message,
      },
    ];
    navigate(`${location.pathname}${location.search}`, {
      state: { message: formattedChat },
    });
  };

  return (
    <div className="">
      <div>
        <div
          className="flex items-center px-2 py-2 cursor-pointer"
          onClick={() => setIsHistoryExpanded(!isHistoryExpanded)}
        >
          {isHistoryExpanded ? (
            <ChevronUp className="w-4 h-4" />
          ) : (
            <ChevronDown className="w-4 h-4" />
          )}
          <span
            className="flex-1 text-gray-700 text-sm"
            style={{ fontSize: "14px" }}
          >
            History
          </span>
        </div>

        {isHistoryExpanded && (
          <div className="px-2 py-2">
            <div className="relative -mx-4">
              <Search className="absolute left-3 top-2.5 w-4 h-4 text-gray-400" />
              <input
                type="text"
                placeholder="Search keyword.."
                className="w-full pl-9 pr-4 py-2 border border-gray-200 rounded-md text-sm"
                style={{ fontSize: "14px" }}
              />
            </div>

            <div className="h-[280px] overflow-y-auto -mx-4 px-4 ">
              {Object.keys(groupedMessages).map((category, i) => (
                <GroupHistoryItem
                  key={i}
                  category={category}
                  messages={groupedMessages[category]}
                  selectedMessage={selectedMessage}
                  handleSelectChat={handleSelectChat}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <hr />
      {/* Workstreams Section */}
      <div className="mt-4">
        <div
          className="flex items-center px-2 py-2 cursor-pointer"
          onClick={() => setIsWorkStreamsExpanded(!isWorkStreamsExpanded)}
        >
          {isWorkStreamsExpanded ? (
            <ChevronUp className="w-4 h-4" />
          ) : (
            <ChevronDown className="w-4 h-4" />
          )}
          <span
            className="flex-1 text-gray-700 text-sm"
            style={{ fontSize: "14px" }}
          >
            Workstreams
          </span>
        </div>

        {isWorkStreamsExpanded && (
          <div>
            <WorkStreamItem
              icon={Users2}
              title="Workforce Optimization"
              defaultExpanded={true}
              isActive={activeItem === "Workforce Optimization"}
              onClick={() => handleItemClick("Workforce Optimization")}
            >
              <WorkStreamItem
                icon={FolderLock}
                title="Private Folder"
                isActive={activeItem === "Private Folder"}
                onClick={() => handleItemClick("Private Folder")}
              >
                <div
                  className={`pl-6 py-2 text-sm truncate cursor-pointer ${
                    activeItem === "Cash Revenue Project Alpha (Private)"
                      ? "bg-blue-50 text-blue-600"
                      : "hover:bg-gray-50"
                  }`}
                  style={{ fontSize: "14px" }}
                  onClick={() =>
                    handleItemClick("Cash Revenue Project Alpha (Private)")
                  }
                >
                  Cash Revenue Project Alpha
                </div>
                <div
                  className={`pl-6 py-2 text-sm truncate cursor-pointer ${
                    activeItem === "Is Project Alpha Good Investment? (Private)"
                      ? "bg-blue-50 text-blue-600"
                      : "hover:bg-gray-50"
                  }`}
                  style={{ fontSize: "14px" }}
                  onClick={() =>
                    handleItemClick(
                      "Is Project Alpha Good Investment? (Private)"
                    )
                  }
                >
                  Is Project Alpha Good Investment?
                </div>
              </WorkStreamItem>

              <WorkStreamItem
                icon={FolderOpen}
                title="Shared Folder"
                isActive={activeItem === "Shared Folder"}
                onClick={() => handleItemClick("Shared Folder")}
              />

              <WorkStreamItem
                icon={Folder}
                title="Technology and Automation"
                isActive={activeItem === "Technology and Automation"}
                onClick={() => handleItemClick("Technology and Automation")}
              />

              <WorkStreamItem
                icon={Folder}
                title="Policy and Cost Analysis"
                isActive={activeItem === "Policy and Cost Analysis"}
                onClick={() => handleItemClick("Policy and Cost Analysis")}
              />

              <WorkStreamItem
                icon={Folder}
                title="Business Planning"
                isActive={activeItem === "Business Planning"}
                onClick={() => handleItemClick("Business Planning")}
              />

              <WorkStreamItem
                icon={Folder}
                title="Value Creation"
                isActive={activeItem === "Value Creation"}
                onClick={() => handleItemClick("Value Creation")}
              />
            </WorkStreamItem>
          </div>
        )}
      </div>
    </div>
  );
}
