import { BrowserRouter, Routes, Route } from "react-router-dom";

import ProtectedRoute from "./middlewares/ProtectedRoute";
import PublicRoute from "./middlewares/PublicRoute";

import DefaultLayout from "./layouts/default";
import AuthLayout from "./layouts/auth";

import Home from "./pages/home";
import ChatPage from "./pages/chat";
import Engagements from "./pages/engagements";
import EngagementsHome from "./pages/engagements/home";
import EngagementsWorkstream from "./pages/engagements/workstream";
import EngagementsDocuments from "./pages/engagements/documents";
import Access from "./pages/access";
import EngagementsWorkstreamHome from "./pages/engagements/workstream/home";
import EngagementsWorkstreamChatbot from "./pages/engagements/workstream/chat";
import DocumentPreviewOverview from "./pages/engagements/documents/preview/overview";
import ChatPageDocPreview from "./pages/engagements/documents/preview/chatbot";
import DocumentPreviewChatbot from "./pages/engagements/documents/preview/chatbot";
import AuthPage from "./pages/auth";
import LogoutPage from "./pages/auth/logout";
import { TabsProvider } from "./provider/TabsProvider";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/auth"
          element={
            <PublicRoute>
              <AuthLayout>
                <AuthPage />
              </AuthLayout>
            </PublicRoute>
          }
        />
        <Route
          path="/signup"
          element={
            <PublicRoute>
              <AuthLayout>
                <AuthPage defaultAuth="register" />
              </AuthLayout>
            </PublicRoute>
          }
        />
        <Route path="/auth/logout" element={<LogoutPage />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <DefaultLayout></DefaultLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/:name/home"
          element={
            <ProtectedRoute>
              <DefaultLayout>
                <TabsProvider>
                  <Home />
                </TabsProvider>
              </DefaultLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/:name/engagements"
          element={
            <ProtectedRoute>
              <DefaultLayout>
                <Engagements />
              </DefaultLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/:name/engagements/home"
          element={
            <ProtectedRoute>
              <DefaultLayout>
                <EngagementsHome />
              </DefaultLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/:name/engagements/workstreams"
          element={
            <ProtectedRoute>
              <DefaultLayout>
                <EngagementsWorkstream />
              </DefaultLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/:name/engagements/workstreams/home/"
          element={
            <ProtectedRoute>
              <DefaultLayout>
                <EngagementsWorkstreamHome />
              </DefaultLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/:name/engagements/workstreams/chatbot"
          element={
            <ProtectedRoute>
              <DefaultLayout>
                <EngagementsWorkstreamChatbot />
              </DefaultLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/:name/engagements/documents"
          element={
            <ProtectedRoute>
              <DefaultLayout>
                <EngagementsDocuments />
              </DefaultLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/:name/engagements/documents/overview"
          element={
            <ProtectedRoute>
              <DefaultLayout>
                <DocumentPreviewOverview />
              </DefaultLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/:name/engagements/documents/chatbot"
          element={
            <ProtectedRoute>
              <DefaultLayout>
                <DocumentPreviewChatbot />
              </DefaultLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/:name/home/document-upload"
          element={
            <ProtectedRoute>
              <DefaultLayout>
                <ChatPageDocPreview />
              </DefaultLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/:name/access"
          element={
            <ProtectedRoute>
              <DefaultLayout>
                <Access />
              </DefaultLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/:name/home/:id"
          element={
            <ProtectedRoute>
              <DefaultLayout>
                <ChatPage />
              </DefaultLayout>
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
