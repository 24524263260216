import { cn } from "../../../utils/cn";

export const TabItem = ({ item, onClick }) => {
  const { title, selected } = item;
  return (
    <button
      onClick={onClick}
      className={cn(
        "px-5 py-2 flex flex-row gap-x-8 justify-start items-center",
        selected && "text-blue-500 bg-blue-500/10"
      )}
    >
      {title}
    </button>
  );
};

export default TabItem;
