import React, { useState } from "react";
import DocsSvg from "../../icons/DocsSvg";
import Button from "../../components/Button";
import { FileUploader } from "react-drag-drop-files";
import CubeSvg from "../../icons/CubeSvg";
import TriangleSvg from "../../icons/TriangleSvg";
import DocumentCloudSvg from "../../icons/DocumentCloudSvg";

const fileTypes = ["xlx", "pdf", "doc"];
const DocumentUploadPage = () => {
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleChange = (files) => {
    setFile(files);
    setIsUploading(true);
    setProgress(0);

    // Mock upload process
    const uploadInterval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(uploadInterval);
          setIsUploading(false);
          return 100;
        }
        return prev + 10;
      });
    }, 500); // Increase progress every 500ms
  };

  return (
    <div>
      <div className="flex justify-between">
        <div className="font-bold text-xl text-primary flex gap-2">
          <span className="my-auto bg-[#F1F6FE] text-blue-500 p-2 rounded-md">
            <DocsSvg size={16} />
          </span>{" "}
          Document Upload
        </div>

        <div className="flex">
          <label className="my-auto font-productSansLight">
            Would you like to connect the data with Intriq?
          </label>
          <Button variant="secondary" className="ml-2 flex gap-2">
            <span className="my-auto">
              <TriangleSvg size={16} />
            </span>
            <div className="my-auto">Connect</div>
          </Button>
        </div>
      </div>

      <div className="w-full mt-6">
        <FileUploader
          multiple={true}
          handleChange={handleChange}
          name="file"
          types={fileTypes}
          hoverTitle="Drop files here.."
          dropMessageStyle={{ background: "#F1F6FE", borderColor: "#1971F6" }}
        >
          <div className="w-full bg-[#F1F6FE52] border border-dashed border-blue-500 rounded-md p-20 text-center justify-center">
            {isUploading || file ? <DocumentCloudSvg /> : <CubeSvg />}

            {(isUploading || file) && (
              <div className="mt-2 mx-auto w-[300px]">
                <p className="font-semibold text-sm">{file[0]?.name}</p>

                <p className="text-gray-600 text-sm mt-1">
                  {(file[0]?.size / 100000).toFixed(2)}mb | {progress}% •{" "}
                  {(100 / (progress + 1)).toFixed(1)} sec left
                </p>

                <div className="relative mt-2 h-[3px] bg-gray-200 rounded-full overflow-hidden">
                  <div
                    className="absolute top-0 left-0 h-full bg-blue-500"
                    style={{ width: `${progress}%` }}
                  ></div>
                </div>
              </div>
            )}

            {!file && (
              <>
                <div className="mt-2">
                  <span className="font-semibold">Drag & drop files here </span>
                  <span className="text-secondary text-sm font-normal mx-1">
                    or{" "}
                  </span>
                  <Button>Browse Files</Button>
                </div>
                <div className="text-sm text-secondary font-normal mt-1">
                  xlx, pdf, or doc (max 125 GB)
                </div>
              </>
            )}
          </div>
        </FileUploader>
      </div>
    </div>
  );
};

export default DocumentUploadPage;
