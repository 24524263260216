const SendSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#1971F6"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0586 7.09166L7.92522 3.52499C3.13355 1.12499 1.16689 3.09166 3.56689 7.88332L4.29189 9.33332C4.50022 9.75832 4.50022 10.25 4.29189 10.675L3.56689 12.1167C1.16689 16.9083 3.12522 18.875 7.92522 16.475L15.0586 12.9083C18.2586 11.3083 18.2586 8.69166 15.0586 7.09166ZM12.3669 10.625H7.86689C7.52522 10.625 7.24189 10.3417 7.24189 9.99999C7.24189 9.65832 7.52522 9.37499 7.86689 9.37499H12.3669C12.7086 9.37499 12.9919 9.65832 12.9919 9.99999C12.9919 10.3417 12.7086 10.625 12.3669 10.625Z"
        fill="#1971F6"
      />
    </svg>
  );
};

export default SendSvg;
