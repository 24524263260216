import dayjs from "dayjs";

const getDateCategory = (date) => {
  const inputDate = dayjs(date).startOf("day");
  const today = dayjs().startOf("day");
  const yesterday = today.subtract(1, "day");
  const sevenDaysAgo = today.subtract(7, "day");
  const thirtyDaysAgo = today.subtract(30, "day");

  if (inputDate.isSame(today, "day")) return "Today";
  if (inputDate.isSame(yesterday, "day")) return "Yesterday";
  if (inputDate.isAfter(sevenDaysAgo) && inputDate.isBefore(today))
    return "Previous 7 Days";
  if (inputDate.isAfter(thirtyDaysAgo) && inputDate.isBefore(today))
    return "Previous 30 Days";

  if (inputDate.isSame(today, "year")) {
    return inputDate.format("MMMM");
  } else {
    return inputDate.format("YYYY");
  }
};

export const groupMessagesByDate = (messages) => {
  const categoryOrder = [
    "Today",
    "Yesterday",
    "Previous 7 Days",
    "Previous 30 Days",
  ];
  const groupedMessages =
    messages &&
    messages.length > 0 &&
    messages
      .sort((a, b) => dayjs(b.timestamp) - dayjs(a.timestamp))
      .reduce((acc, message) => {
        const date = message.timestamp;
        const category = getDateCategory(dayjs(date));
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(message);
        return acc;
      }, {});

  const sortedGroupedMessages = Object.keys(groupedMessages)
    .sort((a, b) => categoryOrder.indexOf(a) - categoryOrder.indexOf(b))
    .reduce((sortedAcc, key) => {
      sortedAcc[key] = groupedMessages[key];
      return sortedAcc;
    }, {});

  return sortedGroupedMessages;
};
