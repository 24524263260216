import { Bell } from "lucide-react";
import { useLocation, Link } from "react-router-dom";

function Header() {
  const location = useLocation();
  const currentPath = location.pathname;

  const breadcrumbItems = currentPath
    .split("/")
    .filter(Boolean)
    .map((path, index, arr) => {
      const to = "/" + arr.slice(0, index + 1).join("/");
      const isLast = index === arr.length - 1;
      const name =
        path.charAt(0).toUpperCase() + path.slice(1).replace("-", " "); // Mengubah huruf besar dan mengganti tanda hubung

      return (
        <li key={to} className="inline-block text-secondary">
          {/* <Link to={to} className="text-secondary hover:underline"> */}
          {name}
          {/* </Link> */}
          {!isLast && <span className="mx-2">/</span>}
        </li>
      );
    });

  const handleLogout = () => {
    // Clear the auth_key from session storage
    localStorage.removeItem("auth_key");
    // Optionally, you can redirect the user to the login page or refresh the page
    window.location.href = "/auth"; // Change "/login" to your actual login route
  };

  return (
    <header className="bg-gray-50 flex justify-between items-center py-2 pl-1 pr-4">
      <nav className="flex items-center">
        <ol className="list-none p-0 inline-flex ">{breadcrumbItems}</ol>
      </nav>
      <div className="flex items-center gap-2">
        {/* <div className=" border border-borderbase p-1.5 rounded-md relative">
          <div className="absolute top-[-5px] right-[-5px] bg-red-500 text-[10px] text-white rounded w-4 h-3 flex items-center justify-center">
            24
          </div>
          <Bell className="w-4 h-4 text-gray-500 rounded " />
        </div>
        <img
          src="https://i.pravatar.cc/64?img=12"
          alt="Avatar pengguna"
          className="w-8 h-8 rounded-lg"
        /> */}
        <button
          onClick={handleLogout}
          className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
        >
          Log out
        </button>
      </div>
    </header>
  );
}

export default Header;
