import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import Input from "../Input";
import { registerService } from "../../../service/auth";

const Register = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      email: "",
      promo: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      username: Yup.string().required("Required"),
      password: Yup.string().required("Required"),
      email: Yup.string().email().required("Required"),
      promo: Yup.string().optional(),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      setError(null);
      try {
        const response = await registerService(values);
        if (response) {
          setSuccessMessage(response?.detail);
        }
      } catch (error) {
        setError(error?.response?.data?.detail);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div className="flex justify-center items-center w-full p-8">
      <form
        onSubmit={formik.handleSubmit}
        className="bg-white shadow-md w-full lg:w-[500px] p-10 lg:p-[52px] space-y-8"
      >
        <div className="text-center space-y-3 lg:space-y-4">
          <h1 className="text-4xl lg:text-5xl font-normal text-black">
            Register
          </h1>
          <p className="text-sm lg:text-base font-normal text-gray-500">
            Please register account with your details
          </p>
        </div>
        {successMessage ? (
          <p className="text-green-600 text-sm font-normal">{successMessage}</p>
        ) : (
          <div>
            <div>
              <Input
                id="username"
                label="Username"
                type="text"
                name="username"
                placeholder="Enter username"
                required={true}
                formik={formik}
              />
              <div className="h-6"></div>
              <Input
                id="password"
                label="Password"
                type="password"
                name="password"
                placeholder="Enter Password"
                required={true}
                formik={formik}
              />
              <div className="h-6"></div>
              <Input
                id="email"
                label="Business Email"
                type="email"
                name="email"
                placeholder="Enter Business Email"
                required={true}
                formik={formik}
              />
              <div className="h-6"></div>
              <Input
                id="promo"
                label="Promo Code (Optional)"
                type="text"
                name="promo"
                placeholder="Enter Promo Code"
                formik={formik}
              />
              <div className="h-4"></div>
              {error && (
                <p className="text-red-600 text-sm font-normal">{error}</p>
              )}
            </div>
            <div className="space-y-4">
              {!loading ? (
                <button
                  type="submit"
                  className="w-full mt-2 bg-tosca rounded-md py-3 text-black text-base font-medium"
                >
                  Register
                </button>
              ) : (
                <button
                  type="button"
                  className="w-full mt-2 bg-bgbase rounded-md py-3 text-secondary text-base font-medium"
                >
                  Register
                </button>
              )}
              <div className="text-center text-sm font-normal text-gray-500">
                Have an account?
                <span>
                  <button
                    onClick={props.handleLogin}
                    className="text-sm font-medium text-tosca ml-1"
                  >
                    Login
                  </button>
                </span>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default Register;
