import DocsSvg from "../../icons/DocsSvg";
import HomeSvg from "../../icons/HomeSvg";

export const menuItems = [
  {
    label: "Main", // Label Grup Menu
    items: [{ path: "/homepage", label: "Home Page", icon: <HomeSvg /> }],
  },

  {
    label: "Reports", // Label Grup Menu
    items: [
      { path: "/lorem-ipsup", label: "Lorem Ipsum", icon: <DocsSvg /> },
      { path: "/dolor-set", label: "Dolor Set", icon: <HomeSvg /> },
    ],
    other: true,
  },
];
