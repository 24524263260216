import { postData, getData } from "../../utils/apiUtils";

/**
 * Sends a POST request to the /placeholder endpoint with the provided input data.
 *
 * @param {any} input - The input data to be sent in the request body.
 * @returns {Promise<any>} The response data from the server.
 * @throws Will throw an error if the request fails.
 */
export const chatService = async (input) => {
  try {
    const response = await postData(`/chat`, input);
    return response;
  } catch (error) {
    console.error("Error in chat request:", error);
    throw error;
  }
};

export const getChatHistory = async () => {
  try {
    const response = await getData(
      `/conversation_history?filename=1.1_Intriq_-_Descriptions.pdf`
    );
    return response;
  } catch (error) {
    console.error("Error in get chat history:", error);
    throw error;
  }
};
