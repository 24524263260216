const BubleChat = ({
  img = "https://i.pravatar.cc/64",
  text,
  position = "left",
}) => {
  return (
    <div
      className={`flex gap-2 mb-3 ${
        position === "left"
          ? "justify-start text-left"
          : "justify-end text-right"
      }`}
    >
      {position === "left" && (
        <img
          src="/images/chat.png"
          alt="Avatar pengguna"
          className="w-8 h-8 rounded-lg contain"
        />
      )}

      <div className="px-3 py-2 bg-[#F1F6FE] rounded-md max-w-[55%]">
        {text}
      </div>

      {position === "right" && (
        <img src={img} alt="Avatar pengguna" className="w-8 h-8 rounded-lg" />
      )}
    </div>
  );
};

export default BubleChat;
