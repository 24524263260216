import React, { useEffect } from "react";
const BubleChat = ({
  img = "https://i.pravatar.cc/64?img=12",
  text,
  citations,
  position = "left",
}) => {
  useEffect(() => {
    console.log("citationsInBubleChat", citations);
  }, []);
  return (
    <div
      className={`flex gap-2 mb-3 ${
        position === "left"
          ? "justify-start text-left"
          : "justify-end text-right"
      }`}
    >
      {position === "left" && (
        <img
          src="/images/chat.png"
          alt="Avatar pengguna"
          className="w-8 h-8 rounded-lg contain"
        />
      )}
      <div
        className={`
          px-3 
          py-2 
          ${position === "left" ? "bg-[#F1F6FE]" : "bg-blue-500 text-white"}
          rounded-md 
          max-w-[55%] 
          break-all  
          overflow-wrap-break-word 
          whitespace-pre-wrap 
          text-wrap 
          inline-block  
        `}
      >
        {position === "left"
          ? `${text} ${citations ? citations.map((citat) => citat) : ""}`
          : `${text}`}
      </div>
      {position === "right" && (
        <img src={img} alt="Avatar pengguna" className="w-8 h-8 rounded-lg" />
      )}
    </div>
  );
};

export default BubleChat;
