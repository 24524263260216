const buttonVariants = {
  primary: "bg-[#1971F6] text-[#F1F6FE]",
  secondary: "bg-[#F1F6FE] text-[#1971F6]",
};
const Button = ({
  children,
  variant = "primary",
  onClick = () => {},
  className = "",
}) => {
  return (
    <button
      className={`rounded-[4px] py-1 px-4 font-medium ${buttonVariants[variant]} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
