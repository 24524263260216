const DocsFillSvg = ({ size = 16 }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.21669 1.28917C8.97752 1.05001 8.56335 1.21334 8.56335 1.54584V3.58167C8.56335 4.43334 9.28669 5.13917 10.1675 5.13917C10.7217 5.14501 11.4917 5.14501 12.1509 5.14501C12.4834 5.14501 12.6584 4.75417 12.425 4.52084C11.585 3.67501 10.08 2.15251 9.21669 1.28917Z"
        fill="currentColor"
      />
      <path
        d="M11.9583 5.94416H10.2725C8.88998 5.94416 7.76415 4.81832 7.76415 3.43582V1.74999C7.76415 1.42916 7.50165 1.16666 7.18081 1.16666H4.70748C2.91081 1.16666 1.45831 2.33332 1.45831 4.41582V9.58416C1.45831 11.6667 2.91081 12.8333 4.70748 12.8333H9.29248C11.0891 12.8333 12.5416 11.6667 12.5416 9.58416V6.52749C12.5416 6.20666 12.2791 5.94416 11.9583 5.94416ZM6.70831 10.3542H4.37498C4.13581 10.3542 3.93748 10.1558 3.93748 9.91666C3.93748 9.67749 4.13581 9.47916 4.37498 9.47916H6.70831C6.94748 9.47916 7.14581 9.67749 7.14581 9.91666C7.14581 10.1558 6.94748 10.3542 6.70831 10.3542ZM7.87498 8.02082H4.37498C4.13581 8.02082 3.93748 7.82249 3.93748 7.58332C3.93748 7.34416 4.13581 7.14582 4.37498 7.14582H7.87498C8.11415 7.14582 8.31248 7.34416 8.31248 7.58332C8.31248 7.82249 8.11415 8.02082 7.87498 8.02082Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DocsFillSvg;
