const ClockSvg = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99984 1.16666C3.78567 1.16666 1.1665 3.78583 1.1665 7C1.1665 10.2142 3.78567 12.8333 6.99984 12.8333C10.214 12.8333 12.8332 10.2142 12.8332 7C12.8332 3.78583 10.214 1.16666 6.99984 1.16666ZM9.53734 9.0825C9.45567 9.2225 9.30984 9.29833 9.15817 9.29833C9.08234 9.29833 9.0065 9.28083 8.9365 9.23416L7.12817 8.155C6.679 7.88666 6.3465 7.2975 6.3465 6.77833V4.38666C6.3465 4.1475 6.54484 3.94916 6.784 3.94916C7.02317 3.94916 7.2215 4.1475 7.2215 4.38666V6.77833C7.2215 6.98833 7.3965 7.2975 7.57734 7.4025L9.38567 8.48166C9.59567 8.60416 9.66567 8.8725 9.53734 9.0825Z"
        fill="#8393A5"
      />
    </svg>
  );
};

export default ClockSvg;
