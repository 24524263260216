import React, { useEffect } from "react";
import { cva } from "class-variance-authority";

import { TabItem } from "./TabItem";
import { cn } from "../../utils/cn";
import { TabsProvider, useTabs } from "../../provider/TabsProvider";

const tabsCva = cva("border border-container");

const TabsWithContent = ({ className, children, ...props }) => {
  return (
    <TabsProvider>
      <div className={cn(tabsCva({ className }))} {...props}>
        {children}
      </div>
    </TabsProvider>
  );
};

const titleVariants = cva("flex flex-row border-b-2 border-inactive");

const Titles = ({ items, className, defaultCurrentIndex, ...props }) => {
  const { activeTab, setActiveTab } = useTabs();
  useEffect(() => {
    if (defaultCurrentIndex) {
      setActiveTab(defaultCurrentIndex);
    }
  }, [defaultCurrentIndex]);

  return (
    <div className={cn(titleVariants({ className }))} {...props}>
      {items.map(({ title }, index) => (
        <TabItem
          item={{ title, selected: index === activeTab }}
          key={index}
          onClick={() => setActiveTab(index)}
        />
      ))}
    </div>
  );
};

const contentVariants = cva("flex flex-row p-5 h-fit w-full");

const Contents = ({ items, className, ...props }) => {
  const { activeTab } = useTabs();
  const { id, content } = items[activeTab];
  return (
    <div key={id} className={cn(contentVariants({ className }))} {...props}>
      {content}
    </div>
  );
};

TabsWithContent.Titles = Titles;
TabsWithContent.Contents = Contents;

export default TabsWithContent;
