import React from "react";

const HomeSvg = ({ size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1683 4.8825L8.12583 1.65083C7.50166 1.14917 6.49249 1.14917 5.87416 1.645L1.83166 4.8825C1.37666 5.24416 1.08499 6.00833 1.18416 6.58L1.95999 11.2233C2.09999 12.0517 2.89333 12.7225 3.73333 12.7225H10.2667C11.1008 12.7225 11.9 12.0458 12.04 11.2233L12.8158 6.58C12.9092 6.00833 12.6175 5.24416 12.1683 4.8825ZM6.99999 9.04167C6.19499 9.04167 5.54166 8.38833 5.54166 7.58333C5.54166 6.77833 6.19499 6.125 6.99999 6.125C7.80499 6.125 8.45833 6.77833 8.45833 7.58333C8.45833 8.38833 7.80499 9.04167 6.99999 9.04167Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default HomeSvg;
