import React, { useState } from "react";
import Login from "../../components/Form/login";
import Register from "../../components/Form/register";

const AuthPage = ({ defaultAuth = "login" }) => {
  const [auth, setAuth] = useState(defaultAuth);

  return (
    <div className="lg:flex h-screen roboto">
      <div className="relative w-full bg-tosca py-10 lg:py-[70px] px-10 lg:px-[100px] overflow-hidden">
        <img
          src="/images/login/image-login-1.webp"
          alt="Image"
          className="absolute top-0 right-0"
        />
        <img
          src="/images/login/image-login.webp"
          alt="Image"
          className="hidden lg:block absolute bottom-0 right-0 w-[500px]"
        />
        <div className="relative lg:w-2/3 space-y-4 lg:space-y-6">
          <img
            src="/images/logo-black.webp"
            alt="Logo"
            className="h-14 lg:h-[70px]"
          />
          <h1 className="text-2xl md:text-[32px] font-semibold text-black">
            Transforming Consulting
          </h1>
          <p className="text-sm md:text-base font-normal text-black">
            An AI-toolkit accelerating data collection and analysis for
            Management Consultants
          </p>
        </div>
      </div>
      {auth === "login" ? (
        <Login handleRegister={() => setAuth("register")} />
      ) : (
        <Register handleLogin={() => setAuth("login")} />
      )}
    </div>
  );
};

export default AuthPage;
