import { getData, postData } from "../../utils/apiUtils";

/**
 * Retrieve a list of files from the API.
 *
 * @returns {Promise<Object>} The response data containing the list of files.
 * @throws Will throw an error if the request fails.
 */
export const listCompaniesService = async () => {
  try {
    const auth_key = localStorage.getItem("auth_key");
    const response = await getData(`/companies/?auth_key=${auth_key}`);
    return response;
  } catch (error) {
    console.error("Error listing data:", error);
    throw error;
  }
};

export const getCompanyStatusService = async (id) => {
  try {
    const auth_key = localStorage.getItem("auth_key");
    const response = await getData(
      `/companies/${id}/status/?auth_key=${auth_key}`
    );
    return response;
  } catch (error) {
    console.error("Error get data:", error);
    throw error;
  }
};

export const getCompanyInfoService = async (id) => {
  try {
    const auth_key = localStorage.getItem("auth_key");
    const response = await getData(`/companies/${id}/?auth_key=${auth_key}`);
    return response;
  } catch (error) {
    console.error("Error get data:", error);
    throw error;
  }
};

/**
 * Sends a POST request to the /placeholder endpoint with the provided input data.
 *
 * @param {any} input - The input data to be sent in the request body.
 * @returns {Promise<any>} The response data from the server.
 * @throws Will throw an error if the request fails.
 */
export const createCompanyService = async (input) => {
  try {
    const auth_key = localStorage.getItem("auth_key");
    const response = await postData(`/companies/?auth_key=${auth_key}`, input);
    return response;
  } catch (error) {
    console.error("Error in post request:", error);
    throw error;
  }
};
