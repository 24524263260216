import { useState, useRef, useEffect } from "react";
import BubleChat from "../../components/BubleChat";
import { useLocation } from "react-router-dom";
import HomeSvg from "../../icons/HomeSvg";
import DocsFillSvg from "../../icons/DocsFillSvg";
import ClockSvg from "../../icons/ClockSvg";
import EyeSvg from "../../icons/EyeSvg";
import SearchSvg from "../../icons/SearchSvg";
import SendSvg from "../../icons/SendSvg";

const ChatPage = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isChatStarted, setIsChatStarted] = useState(false);

  // Create a ref for the messages container
  const messagesEndRef = useRef(null);

  // Function to scroll to the bottom of the messages
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Use effect to scroll to bottom whenever messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = (e) => {
    e.preventDefault();

    if (!inputValue.trim()) return;

    setIsChatStarted(true);

    // Add user message to the chat
    const userMessage = { position: "right", text: inputValue };

    // Mock AI response
    const aiResponse = {
      position: "left",
      text: `You said: "${inputValue}". That's interesting! Tell me more.`,
    };

    setMessages((prevMessages) => [...prevMessages, userMessage, aiResponse]);
    setInputValue("");
  };

  return (
    <>
      <div className="text-xl flex gap-2 z-50">
        <div className="bg-blue-200 text-blue-500 p-1 rounded-md mr-2">
          <HomeSvg size={18} />
        </div>
        {lastSegment}
        <div className="flex gap-2">
          <div className="my-auto font-productSans  rounded bg-[#F1F6FE] py-1 px-2 !text-xs flex gap-1">
            <span className="m-auto text-gray-400">
              <EyeSvg />
            </span>
            <b>View Document</b>
          </div>
        </div>
        <div className="flex gap-2">
          <div className="my-auto font-productSans border rounded border-orange-400 py-1 px-2 !text-xs flex gap-1">
            <span className="m-auto text-orange-600">
              <DocsFillSvg />
            </span>
            <b>PDF</b>
          </div>
        </div>
        <div className="flex gap-2">
          <div className="my-auto font-productSans border rounded border-gray-400 py-1 px-2 !text-xs flex gap-1">
            <span className="m-auto text-gray-400">
              <ClockSvg />
            </span>
            <b>Dec 2, 2024</b>
          </div>
        </div>

        <div className="absolute right-4 -mt-1.5">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <SearchSvg className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            className="pl-10 pr-3 py-2 w-[300px] bg-[#F9FBFF] text-sm text-primary border border-[#D6E0E7] rounded-[4px] 
        hover:border-blue-300 focus:border-blue-300 focus:outline-none"
            placeholder="Search keyword"
          />
        </div>
      </div>
      <hr className="mt-3 -mx-4 " />
      <div className="h-[calc(100vh-10rem)] flex flex-col justify-center items-center">
        {!isChatStarted ? (
          <div className="w-[60%] text-center">
            <h2 className="text-2xl font-semibold mb-4 flex gap-2 justify-center">
              <span>
                <img
                  src="/images/star.png"
                  alt="Avatar pengguna"
                  className="w-6 h-6 "
                />
              </span>
              What can I help with?
            </h2>
            <form onSubmit={handleSendMessage} className="w-full">
              <div className="relative">
                <input
                  className="p-3 w-full bg-[#F9FBFF] border border-[#D6E0E7] hover:border-blue-300 focus:border-blue-300 focus:outline-none active:border-blue-300 text-primary rounded-[4px]"
                  placeholder="Message [AI Name]"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
                <div
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={handleSendMessage}
                >
                  <SendSvg className="h-5 w-5 text-gray-500 hover:text-blue-500 transition-colors duration-200" />
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className="w-[60%] flex flex-col justify-between h-full ">
            <div className="overflow-y-auto flex-1 mb-4 px-3 ">
              <div className="relative flex py-5 items-center">
                <div className="flex-grow border-t border-[#D6E0E7]"></div>
                <span className="flex-shrink mx-4 border-[#D6E0E7] text-secondary">
                  Sep 28, 2024
                </span>
                <div className="flex-grow border-t border-[#D6E0E7]"></div>
              </div>
              {messages.map((message, index) => (
                <BubleChat
                  key={index}
                  position={message.position}
                  text={message.text}
                />
              ))}
              {/* Ref to scroll to bottom */}
              <div ref={messagesEndRef} />
            </div>

            <form onSubmit={handleSendMessage}>
              <div className="relative">
                <input
                  className="p-3 w-full bg-[#F9FBFF] border border-[#D6E0E7] hover:border-blue-300 focus:border-blue-300 focus:outline-none active:border-blue-300 text-primary rounded-[4px]"
                  placeholder="Message [AI Name]"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
                <div
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={handleSendMessage}
                >
                  <SendSvg className="h-5 w-5 text-gray-500 hover:text-blue-500 transition-colors duration-200" />
                </div>
              </div>
            </form>
          </div>
        )}

        <div className="absolute bottom-4 text-secondary text-center">
          <b>© 2024, Intriq.</b> All Rights Reserved.
        </div>
      </div>
    </>
  );
};

export default ChatPage;
