import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ChevronDown, ChevronRight } from "lucide-react";
import { menuItems } from "./constants";
import { BriefcaseBusiness } from "lucide-react";
import { listCompaniesService, createCompanyService } from "../../service/home";
import Button from "../../components/Button";
import ChatHistorySidebar from "./chat-history";
import CreateClientModal from "./modal/create-client";

function CollapsibleMenuItem({ item }) {
  const location = useLocation();
  const menuKey = location.pathname.split("/")[0];

  const [isExpanded, setIsExpanded] = useState(
    // Auto-expand if any child path matches current location
    item.child.some((childItem) => menuKey === childItem.path?.split("/")[0])
  );

  const hasChildActive = item.child.some((childItem) =>
    location.pathname.startsWith(childItem.path)
  );

  // Effect to update expansion when route changes
  useEffect(() => {
    const isCurrentRouteInChildren = item.child.some(
      (childItem) => menuKey === childItem.path?.split("/")[1]
    );

    setIsExpanded(isCurrentRouteInChildren);
  }, [menuKey, item.child]);

  return (
    <div>
      <div
        className="flex items-center justify-between p-2 rounded-lg cursor-pointer hover:bg-gray-100 mt-2"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div
          className={`flex items-center gap-2 ${
            hasChildActive && "text-blue-500"
          }`}
        >
          {item.icon}
          <span className="font-medium text-primary">{item.label}</span>
        </div>
        {isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
      </div>

      {isExpanded && (
        <div className="space-y-2 mt-2">
          {item.child.map((childItem) => (
            <NavLink
              key={childItem.path}
              to={childItem.path}
              className={({ isActive }) =>
                "pl-6 block p-2 rounded-lg " +
                (isActive
                  ? "text-blue-500 bg-[#F1F6FE]"
                  : "text-gray-600 hover:bg-gray-100")
              }
            >
              {childItem.label}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
}

function toKebabCase(str) {
  return str.toLowerCase().replace(/\s+/g, "-");
}

function Sidebar() {
  const pathname = useLocation().pathname;
  const isChatHistory = pathname.includes("/chatbot");
  const [openModal, setOpenModal] = useState(false);
  const [dataClients, setDataClients] = useState([]);

  // Get list of companies on component mount
  const fetchCompanies = async () => {
    try {
      const companyList = await listCompaniesService();
      const newCompanyList = companyList?.map((item) => {
        const slugName = toKebabCase(item?.name);
        return {
          label: item?.name,
          icon: <BriefcaseBusiness className="w-5 h-5 " />,
          child: [
            { path: `/${slugName}/home?id=${item?.id}`, label: "Home" },
            //{
            //  path: `/${slugName}/engagements/documents?id=${item?.id}`,
            //  label: "Documents",
            //},
            {
              path: `/${slugName}/engagements/workstreams/chatbot?id=${item?.id}`,
              label: "Assistant",
            },
            //{ path: `/${slugName}/engagements?id=${item?.id}`, label: "Engagements" },
            //{ path: `/${slugName}/access?id=${item?.id}`, label: "Access/Staffing" },
          ],
        };
      });
      setDataClients(newCompanyList);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  return (
    <>
      <CreateClientModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        onSubmit={() => fetchCompanies()}
      />
      <div className="bg-gray-50 w-64 min-h-screen overflow-y-auto custom-scrollbar flex flex-col justify-between p-4 text-sm text-primary">
        <div>
          <div className="text-xl mb-6">
            <img src="/images/logo.png" alt="Logo" className="w-16 ml-2" />
          </div>

          {isChatHistory && <ChatHistorySidebar />}
          {/* {!isChatHistory && ( */}
          <nav className="space-y-4">
            <div>
              <div className="text-gray-500 font-medium pl-2 mb-2">Company</div>

              {dataClients?.map((item) => (
                <CollapsibleMenuItem key={item.label} item={item} />
              ))}
            </div>
          </nav>
          {/* )} */}
        </div>
        <div className="mt-10">
          <Button onClick={() => setOpenModal(true)} className="w-full">
            Create New Company
          </Button>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
