const DocumentCloudSvg = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mx-auto
      "
    >
      <path
        d="M23.7 3.315C23.085 2.7 22.02 3.12 22.02 3.975V9.21C22.02 11.4 23.88 13.215 26.145 13.215C27.57 13.23 29.55 13.23 31.245 13.23C32.1 13.23 32.55 12.225 31.95 11.625C29.79 9.45 25.92 5.535 23.7 3.315Z"
        fill="#1971F6"
      />
      <path
        d="M22.1401 28.89C18.6151 29.145 18.6151 34.245 22.1401 34.5H30.4801C31.4851 34.5 32.4751 34.125 33.2101 33.45C35.6851 31.29 34.3651 26.97 31.1101 26.565C29.9401 19.53 19.7701 22.2 22.1701 28.905"
        fill="#1971F6"
      />
      <path
        d="M32.25 16.785V21.66C32.25 22.11 31.59 22.32 31.26 22.005C30.54 21.3 29.655 20.76 28.635 20.445C26.115 19.665 23.19 20.43 21.375 22.35C20.16 23.61 19.545 25.215 19.56 26.955C19.56 27.24 19.425 27.495 19.2 27.66C18 28.575 17.25 30.015 17.25 31.68C17.25 31.8 17.25 31.92 17.265 32.04C17.295 32.52 16.95 32.985 16.455 32.985H12.105C7.485 32.985 3.75 29.985 3.75 24.63V11.355C3.75 6 7.485 3 12.105 3H18.465C19.29 3 19.965 3.675 19.965 4.5V8.835C19.965 12.405 22.845 15.285 26.415 15.285H30.75C31.575 15.285 32.25 15.96 32.25 16.785Z"
        fill="#1971F6"
      />
    </svg>
  );
};

export default DocumentCloudSvg;
