const EyeSvg = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3957 5.3375C11.0482 3.22 9.0765 2.00083 6.99984 2.00083C5.9615 2.00083 4.95234 2.30416 4.03067 2.87C3.109 3.44166 2.28067 4.27583 1.604 5.3375C1.02067 6.25333 1.02067 7.74083 1.604 8.65666C2.9515 10.78 4.92317 11.9933 6.99984 11.9933C8.03817 11.9933 9.04734 11.69 9.969 11.1242C10.8907 10.5525 11.719 9.71833 12.3957 8.65666C12.979 7.74666 12.979 6.25333 12.3957 5.3375ZM6.99984 9.35667C5.69317 9.35667 4.64317 8.30083 4.64317 7C4.64317 5.69916 5.69317 4.64333 6.99984 4.64333C8.3065 4.64333 9.3565 5.69916 9.3565 7C9.3565 8.30083 8.3065 9.35667 6.99984 9.35667Z"
        fill="#1971F6"
      />
      <path
        d="M6.9999 5.33167C6.08407 5.33167 5.3374 6.07833 5.3374 7C5.3374 7.91583 6.08407 8.6625 6.9999 8.6625C7.91574 8.6625 8.66824 7.91583 8.66824 7C8.66824 6.08416 7.91574 5.33167 6.9999 5.33167Z"
        fill="#1971F6"
      />
    </svg>
  );
};

export default EyeSvg;
