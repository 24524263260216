import React from "react";
import CompanyNewsFeed from "../../components/Company/CompanyNewsFeed";
import CompanyOverview from "../../components/Company/CompanyOverview";
import CompanyProfile from "../../components/Company/CompanyProfile";
import CompanyInformation from "../../components/Company/CompanyInformation";
import IndustryInformation from "../../components/Company/IndustryInformation";

export default function Dashboard({ company }) {
  return (
    <div className="mt-6">
      <div className="mx-auto space-y-6">
        <CompanyProfile company={company} />
        <div className="flex flex-col lg:flex-row gap-4">
          <div className="w-full">
            {/*previously className="lg:w-2/3"  Go back to this when enabling CompanyNewsFeed*/}
            <CompanyOverview company={company} />
            <CompanyInformation company={company} />
            <IndustryInformation company={company} />
          </div>
          {/*<div className="lg:w-1/3">
            <CompanyNewsFeed />
          </div>*/}
        </div>
      </div>
    </div>
  );
}
