import React, { useState, useRef, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import BubleChat from "../../../../components/BubleChat";
import { chatService } from "../../../../service/chat";
import ExpandableInput from "../../../../components/ExpandableInput";
import { Tabs } from "../../../../components/Tabs";
import { documentPreviewTabData } from "../../../../constants/tabs";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import DocumentPreviewHeader from "./header";

// Move constants outside component to prevent recreation
const DOCS_DATA = [{ uri: "https://pdfobject.com/pdf/sample.pdf" }];
const DOC_VIEWER_CONFIG = {
  header: {
    disableHeader: true,
    disableFileName: true,
  },
};

const ChatSkeleton = () => (
  <div className="animate-pulse flex space-x-4 mb-4">
    <div className="flex-1 space-y-4 py-1">
      <div className="h-4 bg-gray-300 rounded w-2/4"></div>
      <div className="h-4 bg-gray-300 rounded w-4/6"></div>
    </div>
  </div>
);

// Create a memoized DocViewer component
const MemoizedDocViewer = React.memo(() => (
  <DocViewer
    documents={DOCS_DATA}
    pluginRenderers={DocViewerRenderers}
    config={DOC_VIEWER_CONFIG}
    className="max-h-[78vh] overflow-y-auto"
  />
));

const DocumentPreviewChatbot = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isChatStarted, setIsChatStarted] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [pendingUserMessage, setPendingUserMessage] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, pendingUserMessage]);

  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (!inputValue.trim()) return;

    setIsChatStarted(true);

    if (!startDate) setStartDate(new Date().toLocaleString());

    const userMessage = { position: "right", text: inputValue };
    setPendingUserMessage(userMessage);
    setInputValue("");

    try {
      const responseMessage = await chatService(inputValue);
      const aiText = responseMessage?.output;

      const aiResponse = {
        position: "left",
        text: aiText,
      };

      setMessages((prevMessages) => [...prevMessages, userMessage, aiResponse]);
      setPendingUserMessage(null);
    } catch (error) {
      const errorResponse = {
        position: "left",
        text: "Sorry, I couldn't answer your question. Please try again later.",
      };

      setMessages((prevMessages) => [
        ...prevMessages,
        userMessage,
        errorResponse,
      ]);
      setPendingUserMessage(null);
      console.error("Error sending message:", error);
    }
  };

  const highlightText = (text, keyword) => {
    if (!keyword) return text;

    const parts = text.split(new RegExp(`(${keyword})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === keyword.toLowerCase() ? (
        <span key={index} className="bg-yellow-300">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const filteredMessages = useMemo(
    () =>
      searchKeyword
        ? messages.map((message) => ({
            ...message,
            text: highlightText(message.text, searchKeyword),
          }))
        : messages,
    [messages, searchKeyword]
  );

  return (
    <>
      <DocumentPreviewHeader />
      <Tabs data={documentPreviewTabData} />

      <div className="flex">
        <div className="h-[calc(100vh-20rem)] w-1/2 flex flex-col justify-center items-center">
          {!isChatStarted ? (
            <div className="w-[60%] text-center">
              <h2 className="text-2xl font-semibold mb-4 flex gap-2 justify-center">
                <span>
                  <img
                    src="/images/star.png"
                    alt="Avatar pengguna"
                    className="w-6 h-6"
                  />
                </span>
                What can I help with?
              </h2>
              <form onSubmit={handleSendMessage} className="w-full">
                <div className="relative">
                  <ExpandableInput
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onSend={handleSendMessage}
                    placeholder="Message Assistant"
                  />
                </div>
              </form>
            </div>
          ) : (
            <div className="w-[94%] flex flex-col justify-between min-h-[calc(100vh-14rem)] mt-24">
              <div className="overflow-y-auto flex-1 mb-4 px-3">
                <div className="relative flex py-5 items-center">
                  <div className="flex-grow border-t border-[#D6E0E7]"></div>
                  <span className="flex-shrink mx-4 border-[#D6E0E7] text-secondary">
                    {startDate}
                  </span>
                  <div className="flex-grow border-t border-[#D6E0E7]"></div>
                </div>

                {filteredMessages.map((message, index) => (
                  <BubleChat
                    key={index}
                    position={message.position}
                    text={message.text}
                  />
                ))}

                {pendingUserMessage && (
                  <>
                    <BubleChat
                      position={pendingUserMessage.position}
                      text={pendingUserMessage.text}
                    />
                    <ChatSkeleton />
                  </>
                )}

                <div ref={messagesEndRef} />
              </div>

              <form onSubmit={handleSendMessage}>
                <div className="relative">
                  <ExpandableInput
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onSend={handleSendMessage}
                    placeholder="Message Assistant"
                  />
                </div>
              </form>
            </div>
          )}
          <div className="absolute bottom-4 text-secondary text-center">
            <b>© 2024, Intriq.</b> All Rights Reserved.
          </div>
        </div>

        <div className="w-1/2">
          <MemoizedDocViewer />
        </div>
      </div>
    </>
  );
};

export default DocumentPreviewChatbot;
