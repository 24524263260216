const TriangleSvg = ({ size = "12" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.62509 1.95V6.46C5.62509 6.625 5.54509 6.775 5.41009 6.87L1.80509 9.395C1.51009 9.6 1.09009 9.44 1.02509 9.085C0.950087 8.655 1.05009 8.15 1.35009 7.605L2.91009 4.8L4.38009 2.155C4.49509 1.95 4.62009 1.77 4.75009 1.615C5.05009 1.265 5.62509 1.49 5.62509 1.95Z"
        fill="#1971F6"
      />
      <path
        d="M10.195 9.395L6.59 6.87C6.455 6.775 6.375 6.625 6.375 6.46V1.95C6.375 1.49 6.95 1.265 7.25 1.615C7.38 1.77 7.505 1.95 7.62 2.155L9.09 4.8L10.65 7.605C10.95 8.15 11.05 8.655 10.975 9.085C10.91 9.44 10.49 9.6 10.195 9.395Z"
        fill="#1971F6"
      />
      <path
        d="M2.63009 9.57L5.70509 7.52C5.87009 7.41 6.14009 7.41 6.30509 7.52L9.38009 9.57C10.1951 10.115 10.0601 10.56 9.08009 10.56H2.92509C1.95009 10.555 1.81509 10.11 2.63009 9.57Z"
        fill="#1971F6"
      />
    </svg>
  );
};

export default TriangleSvg;
