import React, { useState, useEffect, useMemo } from "react";
import Tooltip from "../Tooltip";
import ChevronSvg from "../../icons/ChevronSvg";
import { ArrowLeft, ChevronLeft, ChevronRight } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";

function DataTable({
  columns,
  data,
  className,
  onRowClick = () => {},
  loading,
}) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const location = useLocation();
  const newPath = location.pathname.replace("/documents", "");
  const navigate = useNavigate();
  const goToProfile = () => {
    navigate(`${newPath}/workstreams/chatbot${location.search}`); // Изменяем путь на "/profile"
  };
  useEffect(() => {
    console.log("location", location.pathname);
  });

  // Sorting function
  const sortedData = useMemo(() => {
    if (!sortConfig.key) return data;

    return [...data].sort((a, b) => {
      const columnKey = sortConfig.key;

      // Get the values to compare
      const valueA = a[columnKey];
      const valueB = b[columnKey];

      // Handle different types of comparisons
      if (valueA == null) return 1;
      if (valueB == null) return -1;

      // String comparison
      if (typeof valueA === "string" && typeof valueB === "string") {
        return sortConfig.direction === "ascending"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      // Numeric comparison
      if (typeof valueA === "number" && typeof valueB === "number") {
        return sortConfig.direction === "ascending"
          ? valueA - valueB
          : valueB - valueA;
      }

      // Default fallback
      return 0;
    });
  }, [data, sortConfig]);

  // Pagination
  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return sortedData.slice(startIndex, startIndex + itemsPerPage);
  }, [sortedData, currentPage, itemsPerPage]);

  // Total pages calculation
  const totalPages = Math.ceil(sortedData.length / itemsPerPage);

  // Sorting handler
  const handleSort = (key) => {
    let direction = "ascending";

    // If we're already sorting by this column
    if (sortConfig.key === key) {
      // Toggle direction
      direction =
        sortConfig.direction === "ascending" ? "descending" : "ascending";
    }

    setSortConfig({ key, direction });
  };

  // Row selection handlers
  const handleSelectAllRows = (event) => {
    if (event.target.checked) {
      setSelectedRows(paginatedData);
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowSelect = (event, row) => {
    if (event.target.checked) {
      setSelectedRows((prev) => [...prev, row]);
    } else {
      setSelectedRows((prev) => prev.filter((r) => r !== row));
    }
  };

  // Pagination handlers
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to first page
  };

  return (
    <div className={`${className}`}>
      <div className="overflow-x-auto border-t rounded-md">
        <table className="min-w-full divide-y divide-gray-200 border-b">
          <thead className="bg-gray-50">
            <tr>
              {/* Checkbox for selecting all rows */}
              <th className="w-[45px] px-4 py-2">
                <input
                  type="checkbox"
                  checked={
                    selectedRows.length === paginatedData.length &&
                    paginatedData.length > 0
                  }
                  onChange={handleSelectAllRows}
                  className="form-checkbox -3.5 w-3.5 text-blue-600"
                />
              </th>
              {columns.map((column) => (
                <th
                  key={column.key}
                  scope="col"
                  className={`px-6 py-2 text-left text-xs font-medium text-secondary tracking-wider border-l cursor-pointer`}
                  onClick={() =>
                    column.sortable !== false && handleSort(column.key)
                  }
                >
                  <div className="flex items-center">
                    {column.label}
                    {column.sortable !== false && (
                      <span className="ml-2">
                        <ChevronSvg />
                      </span>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>

          <tbody className="bg-white divide-y divide-gray-200 font-helveticaNeue font-normal text-secondary">
            {loading && (
              <tr className="hover:bg-blue-50 cursor-pointer">
                <td className="w-[45px] whitespace-nowrap border-l">
                  <div className="w-[25px] h-4 mx-auto bg-gray-200 animate-pulse"></div>
                </td>
                {columns.map((_, index) => (
                  <td
                    className="px-6 py-2 whitespace-nowrap border-l"
                    key={index + "skeleton"}
                  >
                    <div className="w-16 h-4 bg-gray-200 animate-pulse"></div>
                  </td>
                ))}
              </tr>
            )}
            {!loading &&
              paginatedData?.map((item, index) => (
                <tr key={index} className="hover:bg-blue-50">
                  <td className="w-[45px] px-4 py-2">
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(item)}
                      onChange={(event) => handleRowSelect(event, item)}
                      className="form-checkbox h-3.5 w-3.5 text-blue-600"
                    />
                  </td>
                  {columns.map((column) => {
                    const value = item[column.key];
                    return (
                      <td
                        key={column.key}
                        className={`px-6 py-2 whitespace-nowrap border-l cursor-pointer`}
                        //onClick={() => onRowClick(item)}
                        onClick={() => goToProfile()}
                      >
                        <Tooltip text={"Powered by AI for Smarter Solutions"}>
                          {column.render ? column.render(value, item) : value}
                        </Tooltip>
                      </td>
                    );
                  })}
                </tr>
              ))}
          </tbody>
        </table>

        {/* Bottom controls */}
        <div className="flex justify-between text-secondary items-center p-4">
          {/* Items per page selector */}
          <div className="flex items-center space-x-2">
            <span>Items per page:</span>
            <select
              value={itemsPerPage}
              onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}
              className="form-select border rounded-md"
            >
              {[5, 10, 25, 50, 100].map((num) => (
                <option key={num} value={num}>
                  {num}
                </option>
              ))}
            </select>
            <span>
              {`${(currentPage - 1) * itemsPerPage + 1} - 
                ${Math.min(currentPage * itemsPerPage, sortedData.length)} 
                of ${sortedData.length}`}
            </span>
          </div>

          {/* Pagination */}
          <div className="flex items-center space-x-2">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="p-1.5 border border-borderbase rounded-md hover:bg-blue-50 "
            >
              <ChevronLeft className="w-4 h-4" />
            </button>
            <button className="px-3 py-1 border border-blue-300 text-blue-500 bg-blue-50 rounded-md disabled:opacity-50">
              {currentPage}
            </button>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="p-1.5 border border-borderbase rounded-md hover:bg-blue-50"
            >
              <ChevronRight className="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataTable;
