import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { List, PencilLine } from "lucide-react";
import { X, HelpCircle, FolderUp } from "lucide-react";
import Button from "../../../../components/Button";
import { uploadDocumentService } from "../../../../service/document";
import { FileUploader } from "react-drag-drop-files";
import DocumentCloudSvg from "../../../../icons/DocumentCloudSvg";
import CubeSvg from "../../../../icons/CubeSvg";
const fileTypes = ["xlx", "pdf", "doc"];

export default function UploadData() {
  const [selectedMonth, setSelectedMonth] = useState("Choose months");
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadError, setUploadError] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(null);
  const [typeError, setTypeError] = useState(null); // Menyimpan error tipe file

  const [searchParams, setSearchParams] = useSearchParams();

  // Initialize selected tab from URL query parameter, default to 'existing'
  const [selectedTab, setSelectedTab] = useState(() => {
    return searchParams.get("tab") || "existing";
  });

  // Initialize step from URL query parameter, default to 1
  const [step, setStep] = useState(() => {
    const stepParam = searchParams.get("step");
    return stepParam ? parseInt(stepParam, 10) : 1;
  });

  const changeStep = (newStep) => {
    // Update step and directly set query param
    setStep(newStep);
    searchParams.set("step", newStep.toString());
    setSearchParams(searchParams, { replace: true });
  };

  const handleChangeTab = (tab) => {
    // Update tab and directly set query param
    setSelectedTab(tab);
    searchParams.set("tab", tab);
    setSearchParams(searchParams, { replace: true });
  };

  const resetState = () => {
    setFile(null);
    setIsUploading(false);
    setProgress(0);
    setUploadError(null);
    setUploadSuccess(null);
    setTypeError(null);
  };

  const handleChange = async (files) => {
    setFile(files);
    setIsUploading(true);
    setProgress(0);
    setUploadError(null);
    setUploadSuccess(null);
    setTypeError(null);

    const fakeProgressInterval = setInterval(() => {
      setProgress((prev) => {
        const nextProgress = prev + 10;
        return nextProgress >= 100 ? 100 : nextProgress;
      });
    }, 1000);

    try {
      await uploadDocumentService(files, {}, (realProgress) => {
        console.log("Real progress:", realProgress);
      });

      clearInterval(fakeProgressInterval);

      setProgress(100);
      setIsUploading(false);
      setUploadSuccess("File successfully uploaded!");

      setTimeout(resetState, 3000);
    } catch (error) {
      console.error("Upload failed:", error);
      setUploadError("File upload failed! Something went wrong.");
      setIsUploading(false);
      clearInterval(fakeProgressInterval);
      setProgress(0);
    }
  };

  const handleTypeError = (errorFile) => {
    setTypeError(errorFile);
    setTimeout(() => setTypeError(null), 2000);
  };

  return (
    <div className="border border-gray-200 rounded-lg overflow-hidden mt-6">
      <div className="p-5">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h1 className="text-[16px]  text-gray-900">Upload Data</h1>
            {/* <p className="text-gray-500 font-productSans mt-1">
              Please choose your preferred method.
            </p> */}
          </div>
          {/* <Button onClick={() => changeStep(2)}> Continue</Button> */}

          <FileUploader
            multiple={false}
            handleChange={handleChange}
            onTypeError={handleTypeError} // Menangani error tipe file
            name="file"
            types={fileTypes}
            hoverTitle="Drop files here.."
            dropMessageStyle={{
              background: "#F1F6FE",
              borderColor: "#1971F6",
            }}
          >
            <div
              className={`w-full ${
                typeError
                  ? "bg-red-100 border-red-500"
                  : "bg-[#F1F6FE52] border-blue-500"
              } border border-dashed rounded-md p-20 text-center justify-center`}
            >
              {uploadSuccess && (
                <div className="text-green-500 mb-4">{uploadSuccess}</div>
              )}

              {uploadError && (
                <div className="text-red-500 mb-4">{uploadError}</div>
              )}

              {isUploading || file ? <DocumentCloudSvg /> : <CubeSvg />}

              {typeError && (
                <div className="text-red-500 mb-4 font-semibold">
                  {typeError}
                </div>
              )}

              {(isUploading || file) && (
                <div className="mt-2 mx-auto w-[300px]">
                  <p className="font-semibold text-sm">{file?.name}</p>
                  <p className="text-gray-600 text-sm mt-1">
                    {(file?.size / 100000).toFixed(2)}mb | {progress}% •
                    {(10 - progress / 10).toFixed(1)} sec left
                  </p>
                  <div className="relative mt-2 h-[3px] bg-gray-200 rounded-full overflow-hidden">
                    <div
                      className="absolute top-0 left-0 h-full bg-blue-500"
                      style={{ width: `${progress}%` }}
                    ></div>
                  </div>
                </div>
              )}

              {!file && !typeError && (
                <>
                  <div className="mt-2 grid grid-cols-1 gap-1">
                    <span className="font-semibold">
                      Drag & drop files here{" "}
                    </span>
                    <span className="text-secondary text-sm font-normal mx-1">
                      or{" "}
                    </span>
                    <Button className="bg-[#F1F6FE] text-blue-500 w-30 mx-auto flex items-center">
                      <FolderUp className="w-3.5 h-3.5 mr-2" />
                      Browse Files
                    </Button>
                  </div>
                </>
              )}
            </div>
          </FileUploader>
        </div>

        <hr className="border-t border-gray-200 mb-6" />

        <div className="grid grid-cols-2 gap-4">
          {/* Existing Data Tab */}
          {/*<button
            onClick={() => handleChangeTab("existing")}
            className={`
              relative p-12 rounded-lg border text-center
              ${
                selectedTab === "existing"
                  ? "border-[#1971F6] bg-[#F0F7FF]"
                  : "border-gray-200 hover:border-gray-300"
              }
            `}
          >
            <div className="flex flex-col items-center gap-4">
              <div
                className={`
                p-2 rounded-lg
                ${selectedTab === "existing" ? "bg-[#1971F6]" : "bg-gray-100"}
              `}
              >
                <List
                  className={`w-6 h-6 ${
                    selectedTab === "existing" ? "text-white" : "text-gray-500"
                  }`}
                />
              </div>
              <div>
                <h2
                  className={`
                  text-lg font-medium mb-1
                  ${
                    selectedTab === "existing"
                      ? "text-[#1971F6]"
                      : "text-gray-900"
                  }
                `}
                >
                  Use existing data
                </h2>
                <p className="text-gray-500 text-sm">
                  Select from available lists
                </p>
              </div>
            </div>
          </button>*/}

          {/* Generate List Tab */}
          {/* <button
            onClick={() => handleChangeTab("generate")}
            className={`
              relative p-12 rounded-lg border text-center
              ${
                selectedTab === "generate"
                  ? "border-[#1971F6] bg-[#F0F7FF]"
                  : "border-gray-200 hover:border-gray-300"
              }
            `}
          >
            <div className="flex flex-col items-center gap-4">
              <div
                className={`
                p-2 rounded-lg
                ${selectedTab === "generate" ? "bg-[#1971F6]" : "bg-gray-100"}
              `}
              >
                <PencilLine
                  className={`w-6 h-6 ${
                    selectedTab === "generate" ? "text-white" : "text-gray-500"
                  }`}
                />
              </div>
              <div>
                <h2
                  className={`
                  text-lg font-medium mb-1
                  ${
                    selectedTab === "generate"
                      ? "text-[#1971F6]"
                      : "text-gray-900"
                  }
                `}
                >
                  Generate a list
                </h2>
                <p className="text-gray-500 text-sm">
                  Create one based on a query
                </p>
              </div>
            </div>
          </button>*/}
        </div>
      </div>
    </div>
  );
}
