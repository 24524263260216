import { NavLink } from "react-router-dom";
import { menuItems } from "./constants";

function Sidebar() {
  return (
    <div className="bg-gray-50 w-64 min-h-screen p-4 text-sm text-primary">
      <div className="text-xl mb-6">
        <img src="/images/logo.png" alt="Logo" className="w-16 ml-2" />
      </div>
      <nav className="space-y-4">
        {menuItems.map((group) => (
          <div key={group.label}>
            <div className="text-gray-500 font-medium pl-2">{group.label}</div>
            {/* Key untuk grup */}
            {group.items.map((item) => (
              <NavLink
                key={item.path}
                to={item.path}
                className={({ isActive }) =>
                  "flex items-center p-2 rounded-lg gap-2 font-medium " /* Tambahkan indentasi */ +
                  (isActive
                    ? " text-blue-500 bg-white"
                    : "text-secondary hover:bg-white")
                }
              >
                {item.icon}
                <span className="ml-1">{item.label}</span>
              </NavLink>
            ))}
          </div>
        ))}
      </nav>
    </div>
  );
}

export default Sidebar;
