import { Lightbulb } from "lucide-react";

import TabsWithContent from "../../TabsWithContent";
import { RevenueStreamItem } from "../CompanyOverview";

function CompanyInformationContent({ content }) {
  return (
    <div className="p-4 grid grid-cols-2 gap-5">
      {content.map(
        (item, index /* Updated to iterate over section.content */) => (
          <div key={index} className="border-r border-gray-200 -mr-2.5 pr-3">
            <RevenueStreamItem
              icon={<span className="text-gray-500">{index + 1}</span>}
              title={item?.title}
              description={item?.body}
            />
          </div>
        )
      )}
    </div>
  );
}

export default function CompanyInformation({ company }) {
  return (
    <div className="bg-white rounded-lg border border-gray-200">
      <div className="p-4 border-b border-gray-200 flex items-center gap-2">
        <Lightbulb className="w-5 h-5 text-gray-500" />
        <h2 className="font-medium">Company Information</h2>
      </div>
      {company && (
        <TabsWithContent>
          <TabsWithContent.Titles
            items={company?.snippets
              .slice(1, company?.snippets.length)
              .map((snippet, index) => ({ title: snippet?.title, id: index }))}
          />
          <TabsWithContent.Contents
            items={company?.snippets
              .slice(1, company?.snippets.length)
              .map((snippet, index) => ({
                id: index,
                content: (
                  <CompanyInformationContent content={snippet?.content} />
                ),
              }))}
          />
        </TabsWithContent>
      )}
    </div>
  );
}
