import { useState, useEffect } from "react";
import Tooltip from "../Tooltip";
import ChevronSvg from "../../icons/ChevronSvg";
// import { ChevronUpIcon, ChevronDownIcon } from "lucide-react";

function DataTable({ columns, data, onQuery, className, onRowClick }) {
  const [query, setQuery] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  // Filtering effect
  useEffect(() => {
    const results = data.filter((item) =>
      Object.values(item).some(
        (value) =>
          value && value.toString().toLowerCase().includes(query.toLowerCase())
      )
    );
    setFilteredData(results);
  }, [query, data]);

  // Sorting function
  const sortedData = (() => {
    if (!sortConfig.key) return filteredData;

    return [...filteredData].sort((a, b) => {
      const columnKey = sortConfig.key;

      // Get the values to compare
      const valueA = a[columnKey];
      const valueB = b[columnKey];

      // Handle different types of comparisons
      if (valueA == null) return 1;
      if (valueB == null) return -1;

      // String comparison
      if (typeof valueA === "string" && typeof valueB === "string") {
        return sortConfig.direction === "ascending"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      // Numeric comparison
      if (typeof valueA === "number" && typeof valueB === "number") {
        return sortConfig.direction === "ascending"
          ? valueA - valueB
          : valueB - valueA;
      }

      // Default fallback
      return 0;
    });
  })();

  // Sorting handler
  const handleSort = (key) => {
    let direction = "ascending";

    // If we're already sorting by this column
    if (sortConfig.key === key) {
      // Toggle direction
      direction =
        sortConfig.direction === "ascending" ? "descending" : "ascending";
    }

    setSortConfig({ key, direction });
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    onQuery(event.target.value);
  };

  return (
    <div className={`overflow-x-auto ${className} border-t rounded-md`}>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="w-[45px]"></th>
            {columns.map((column) => (
              <th
                key={column.key}
                scope="col"
                className={`px-6 py-2 text-left text-xs font-medium text-secondary tracking-wider border-l cursor-pointer`}
                onClick={() =>
                  column.sortable !== false && handleSort(column.key)
                }
              >
                <div className="flex items-center">
                  {column.label}
                  {column.sortable !== false && (
                    <span className="ml-2">
                      <ChevronSvg />
                    </span>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {sortedData.map((item, index) => (
            <tr
              key={index}
              className="hover:bg-blue-50 cursor-pointer"
              onClick={() => onRowClick(item)}
            >
              <td className="w-[45px] text-center">
                <Tooltip text={"Powered by AI for Smarter Solutions"}>
                  {index + 1}
                </Tooltip>
              </td>
              {columns.map((column) => {
                const value = item[column.key];
                return (
                  <td
                    key={column.key}
                    className={`px-6 py-2 whitespace-nowrap border-l`}
                  >
                    <Tooltip text={"Powered by AI for Smarter Solutions"}>
                      {column.render ? column.render(value, item) : value}
                    </Tooltip>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default DataTable;
