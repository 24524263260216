import React from "react";
import DataTable from "../../components/DataTable";
import Button from "../../components/Button";
import HomeSvg from "../../icons/HomeSvg";
import DocsSvg from "../../icons/DocsSvg";
import { useNavigate } from "react-router-dom";
import DocsFillSvg from "../../icons/DocsFillSvg";
import UploadSvg from "../../icons/UploadSvg";

const renderDocType = (type) => {
  if (type === "pdf") {
    return (
      <div className="flex gap-2 uppercase">
        <div className=" bg-orange-200 p-1 rounded">
          <span className="m-auto text-orange-600">
            <DocsFillSvg />
          </span>
        </div>
        <div className="my-auto"> {type}</div>
      </div>
    );
  }

  if (type === "xls") {
    return (
      <div className="flex gap-2 uppercase">
        <div className=" bg-green-200 p-1 rounded">
          <span className="m-auto text-green-600">
            <DocsFillSvg />
          </span>
        </div>
        <div className="my-auto"> {type}</div>
      </div>
    );
  }

  if (type === "doc") {
    return (
      <div className="flex gap-2 uppercase">
        <div className=" bg-blue-200 p-1 rounded">
          <span className="m-auto text-blue-600">
            <DocsFillSvg />
          </span>
        </div>
        <div className="my-auto"> {type}</div>
      </div>
    );
  }
  return (
    <div className="flex gap-2 uppercase">
      <div className=" bg-gray-200 p-1 rounded">
        <span className="m-auto text-gray-600">
          <DocsFillSvg />
        </span>
      </div>
      <div className="my-auto"> {type}</div>
    </div>
  );
};

const renderStatus = (status) => {
  if (status === "IN_PROGRESS") {
    return (
      <div className="flex gap-2">
        <div className="my-auto text-orange-400 border rounded border-orange-400 py-1 px-2 !text-xs">
          <b>{status}</b>
        </div>
      </div>
    );
  }

  if (status === "QUERY") {
    return (
      <div className="flex gap-2">
        <div className="my-auto text-green-400 border rounded border-green-400 py-1 px-2 !text-xs">
          <b>{status}</b>
        </div>
      </div>
    );
  }
  return (
    <div className="flex gap-2 uppercase">
      <div className=" bg-green-200 p-1 rounded">
        <span className="m-auto text-green-600">
          <DocsFillSvg />
        </span>
      </div>
      <div className="my-auto"> {status}</div>
    </div>
  );
};

const Home = () => {
  const navigate = useNavigate();
  const columns = [
    { key: "name", label: "File Name" },
    {
      key: "type",
      label: "Document Type",
      render: (type) => renderDocType(type),
    },
    { key: "date", label: "Date Uploaded" },
    {
      key: "status",
      label: "Status",
      render: (status) => renderStatus(status),
    },
  ];

  const data = [
    // Data contoh Anda di sini
    {
      name: "File1.pdf",
      type: "pdf",
      date: "2024-03-01",
      status: "IN_PROGRESS",
    },
    {
      name: "File2.docx",
      type: "doc",
      date: "2024-03-02",
      status: "QUERY",
    },
    {
      name: "File3.txt",
      type: "txt",
      date: "2024-03-02",
      status: "QUERY",
    },
    {
      name: "File4.xls",
      type: "xls",
      date: "2024-03-02",
      status: "QUERY",
    },
  ];

  const handleQuery = (query) => {
    console.log("q:", query);
  };

  const handleRowClick = (data) => {
    navigate(`/homepage/${data.name}`);
  };

  return (
    <div className="p-4 ounded-md text-primary">
      <div className="text-xl font-semibold text-blue-500 flex align-middle  gap-2 ">
        <div className="bg-blue-200 p-1 rounded-md">
          <HomeSvg size={20} />
        </div>
        <span className="text-primary">Homepage</span>
      </div>
      <div className="rounded-md border bg-[#FDFEFF] mt-6">
        <div className="flex justify-between w-full mt-4 pr-4">
          <div className="pl-6 flex align-middle gap-2 ">
            <span className="my-auto text-secondary">
              <DocsSvg size={20} />
            </span>
            <span className="text-lg font-semibold my-auto">All Document</span>
            <span className="text-secondary my-auto pt-0.5 font-normal  font-productSans">
              Overview of every files or documents
            </span>
          </div>
          <Button
            onClick={() => {
              navigate("/homepage/document-upload");
            }}
          >
            <div className="flex gap-2">
              <div className="my-auto">
                <UploadSvg size="16" />
              </div>{" "}
              Upload Document
            </div>
          </Button>
        </div>

        <DataTable
          columns={columns}
          data={data}
          onQuery={handleQuery}
          className="mt-4"
          onRowClick={handleRowClick}
        />
      </div>
    </div>
  );
};

export default Home;
