const CubeSvg = () => {
  return (
    <svg
      width="37"
      height="36"
      viewBox="0 0 37 36"
      fill="none"
      className="mx-auto"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.88 3.38999L29.645 8.65499C30.785 9.26999 30.785 11.025 29.645 11.64L19.88 16.905C19.01 17.37 17.99 17.37 17.12 16.905L7.355 11.64C6.215 11.025 6.215 9.26999 7.355 8.65499L17.12 3.38999C17.99 2.92499 19.01 2.92499 19.88 3.38999Z"
        stroke="#1971F6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.915 15.195L14.99 19.74C16.115 20.31 16.835 21.465 16.835 22.725V31.305C16.835 32.55 15.53 33.345 14.42 32.79L5.345 28.245C4.22 27.675 3.5 26.52 3.5 25.26V16.68C3.5 15.435 4.805 14.64 5.915 15.195Z"
        stroke="#1971F6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.085 15.195L22.01 19.74C20.885 20.31 20.165 21.465 20.165 22.725V31.305C20.165 32.55 21.47 33.345 22.58 32.79L31.655 28.245C32.78 27.675 33.5 26.52 33.5 25.26V16.68C33.5 15.435 32.195 14.64 31.085 15.195Z"
        stroke="#1971F6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CubeSvg;
