"use client";

import { X, HelpCircle, Calendar, Upload, FolderUp } from "lucide-react";
import { useState } from "react";
import { uploadDocumentService } from "../../../service/document";
import { FileUploader } from "react-drag-drop-files";
import Button from "../../../components/Button";
import DocumentCloudSvg from "../../../icons/DocumentCloudSvg";
import CubeSvg from "../../../icons/CubeSvg";

const fileTypes = ["xlx", "pdf", "doc"];
export default function AddEngagementModal({ isOpen, onClose }) {
  const [selectedMonth, setSelectedMonth] = useState("Choose months");
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadError, setUploadError] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(null);
  const [typeError, setTypeError] = useState(null); // Menyimpan error tipe file

  if (!isOpen) return null;

  const resetState = () => {
    setFile(null);
    setIsUploading(false);
    setProgress(0);
    setUploadError(null);
    setUploadSuccess(null);
    setTypeError(null);
  };

  const handleChange = async (files) => {
    setFile(files);
    setIsUploading(true);
    setProgress(0);
    setUploadError(null);
    setUploadSuccess(null);
    setTypeError(null);

    const fakeProgressInterval = setInterval(() => {
      setProgress((prev) => {
        const nextProgress = prev + 10;
        return nextProgress >= 100 ? 100 : nextProgress;
      });
    }, 1000);

    try {
      await uploadDocumentService(files, {}, (realProgress) => {
        console.log("Real progress:", realProgress);
      });

      clearInterval(fakeProgressInterval);

      setProgress(100);
      setIsUploading(false);
      setUploadSuccess("File successfully uploaded!");

      setTimeout(resetState, 3000);
    } catch (error) {
      console.error("Upload failed:", error);
      setUploadError("File upload failed! Something went wrong.");
      setIsUploading(false);
      clearInterval(fakeProgressInterval);
      setProgress(0);
    }
  };

  const handleTypeError = (errorFile) => {
    setTypeError(errorFile);
    setTimeout(() => setTypeError(null), 2000);
  };

  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg w-[500px] relative">
        <div className="flex items-center justify-between p-4 border-b border-gray-200">
          <h2 className="text-lg font-helveticaNeue font-medium">
            Add New Engagement
          </h2>
          <button
            className="text-gray-400 hover:text-gray-600"
            onClick={onClose}
          >
            <X className="h-5 w-5" />
          </button>
        </div>
        <p className="text-primary font-helveticaNeue font-normal text-xs mb-6 bg-[#F1F6FE] px-6 py-1">
          Ensure all required fields are completed accurately before submission.
        </p>
        <div className="p-6 -mt-6">
          <div className="space-y-4">
            <div>
              <div className="flex items-center gap-1 mb-1.5">
                <label className="text-sm font-helveticaNeue font-medium">
                  Owner
                </label>
                <HelpCircle className="h-4 w-4 text-gray-400" />
              </div>
              <input
                type="text"
                placeholder="Enter owner"
                className="w-full px-3 py-2 font-helveticaNeue font-normal border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <div>
              <div className="flex items-center gap-1 mb-1.5">
                <label className="text-sm font-helveticaNeue font-medium">
                  Upload Document
                </label>
                <HelpCircle className="h-4 w-4 text-gray-400" />
              </div>
              <FileUploader
                multiple={false}
                handleChange={handleChange}
                onTypeError={handleTypeError} // Menangani error tipe file
                name="file"
                types={fileTypes}
                hoverTitle="Drop files here.."
                dropMessageStyle={{
                  background: "#F1F6FE",
                  borderColor: "#1971F6",
                }}
              >
                <div
                  className={`w-full ${
                    typeError
                      ? "bg-red-100 border-red-500"
                      : "bg-[#F1F6FE52] border-blue-500"
                  } border border-dashed rounded-md p-20 text-center justify-center`}
                >
                  {uploadSuccess && (
                    <div className="text-green-500 mb-4">{uploadSuccess}</div>
                  )}

                  {uploadError && (
                    <div className="text-red-500 mb-4">{uploadError}</div>
                  )}

                  {isUploading || file ? <DocumentCloudSvg /> : <CubeSvg />}

                  {typeError && (
                    <div className="text-red-500 mb-4 font-semibold">
                      {typeError}
                    </div>
                  )}

                  {(isUploading || file) && (
                    <div className="mt-2 mx-auto w-[300px]">
                      <p className="font-semibold text-sm">{file?.name}</p>
                      <p className="text-gray-600 text-sm mt-1">
                        {(file?.size / 100000).toFixed(2)}mb | {progress}% •
                        {(10 - progress / 10).toFixed(1)} sec left
                      </p>
                      <div className="relative mt-2 h-[3px] bg-gray-200 rounded-full overflow-hidden">
                        <div
                          className="absolute top-0 left-0 h-full bg-blue-500"
                          style={{ width: `${progress}%` }}
                        ></div>
                      </div>
                    </div>
                  )}

                  {!file && !typeError && (
                    <>
                      <div className="mt-2 grid grid-cols-1 gap-1">
                        <span className="font-helveticaNeue font-semibold">
                          Drag & drop files here{" "}
                        </span>
                        <span className="text-secondary text-sm font-normal mx-1">
                          or{" "}
                        </span>
                        <Button className="bg-[#F1F6FE] text-blue-500 w-30 mx-auto flex items-center">
                          <FolderUp className="w-3.5 h-3.5 mr-2" />
                          Browse Files
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </FileUploader>
            </div>

            <div>
              <div className="flex items-center gap-1 mb-1.5">
                <label className="text-sm font-helveticaNeue font-medium">
                  Key Client Contact
                </label>
                <HelpCircle className="h-4 w-4 text-gray-400" />
              </div>
              <input
                type="text"
                placeholder="Enter key client contact"
                className="w-full px-3 py-2 font-helveticaNeue font-normal border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <div className="flex items-center gap-1 mb-1.5">
                  <label className="text-sm font-helveticaNeue font-medium">
                    Start Date
                  </label>
                  <HelpCircle className="h-4 w-4 text-gray-400" />
                </div>
                <div className="relative">
                  <input
                    type="date"
                    placeholder="DD-MM-YYYY"
                    className="w-full px-3 py-2 font-helveticaNeue font-normal border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  {/* <Calendar className="absolute right-3 top-2.5 h-4 w-4 text-gray-400" /> */}
                </div>
              </div>

              <div>
                <div className="flex items-center gap-1 mb-1.5">
                  <label className="text-sm font-helveticaNeue font-medium">
                    Engagement Terms
                  </label>
                  <HelpCircle className="h-4 w-4 text-gray-400" />
                </div>
                <div className="relative">
                  <select
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 appearance-none"
                  >
                    <option value="Choose months" disabled>
                      Choose months
                    </option>
                    <option value="3">3 months</option>
                    <option value="6">6 months</option>
                    <option value="12">12 months</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-2 p-6 border-t border-gray-200">
          <button
            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
            onClick={onClose}
          >
            Cancel
          </button>
          <button className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">
            Add New
          </button>
        </div>
      </div>
    </div>
  );
}
