import { BrowserRouter, Routes, Route } from "react-router-dom";

import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Home from "./pages/home";
import DocumentUploadPage from "./pages/document";
import ChatPage from "./pages/chat";

function App() {
  return (
    <BrowserRouter>
      <div className="flex bg-slate-50 text-sm font-medium">
        <Sidebar />
        <div className="flex-1">
          <Header />
          <div className="bg-white min-h-[calc(100vh-3.5rem)] rounded-lg p-5">
            <Routes>
              <Route path="/homepage" element={<Home />} />
              <Route
                path="/homepage/document-upload"
                element={<DocumentUploadPage />}
              />
              <Route path="/homepage/:id" element={<ChatPage />} />
            </Routes>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
