"use client";

import React, { useState, useEffect } from "react";
import { X } from "lucide-react";
import { useFormik } from "formik";
import {
  createCompanyService,
  getCompanyStatusService,
} from "../../../service/home";
import * as Yup from "yup";

export default function CreateClientModal({ isOpen, onClose, onSubmit }) {
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("PENDING");
  const [isFetching, setIsFetching] = useState(false);
  const [id, setId] = useState(null);

  const getCompanyStatus = async (id) => {
    try {
      setIsFetching(true);
      const response = await getCompanyStatusService(id);
      const status =
        response?.status === "IN_PROGRESS" ? "IN PROGRESS" : response?.status;
      setLoadingText(status);
    } catch (error) {
      console.error("Error get status company:", error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    let interval;

    if (loading && loadingText !== "DONE" && id) {
      interval = setInterval(() => {
        if (!isFetching) {
          getCompanyStatus(id);
        }
      }, 5000);
    } else if (loading && loadingText == "DONE" && id) {
      interval = setTimeout(() => {
        formik.setFieldValue("name", "");
        setId(null);
        setLoadingText("LOADING");
        setLoading(false);
        onSubmit();
        onClose();
      }, 2000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [loading, loadingText, id, isFetching]);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await createCompanyService(values);
        setId(response?.id);
        setLoadingText(
          response?.status == "PENDING" ? "IN PROGRESS" : response?.status
        );
      } catch (error) {
        console.error("Error create company:", error);
        setLoading(false);
      }
    },
  });

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center">
      <form onSubmit={formik.handleSubmit}>
        <div className="bg-white rounded-lg w-[500px] relative">
          <div className="flex items-center justify-between p-4 border-b border-gray-200">
            <h2 className="text-lg font-helveticaNeue font-medium">
              Create New Company
            </h2>
            <button
              className="text-gray-400 hover:text-gray-600"
              onClick={onClose}
            >
              <X className="h-5 w-5" />
            </button>
          </div>
          <div className="p-6">
            <div className="space-y-4">
              {!loading ? (
                <div>
                  <div className="flex items-center gap-1 mb-1.5">
                    <label className="text-sm font-helveticaNeue font-medium">
                      Company Name
                    </label>
                  </div>
                  <input
                    id="name"
                    type="text"
                    placeholder="Enter company name"
                    className="w-full px-3 py-2 font-helveticaNeue font-normal border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    {...formik.getFieldProps("name")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        formik.handleSubmit();
                      }
                    }}
                  />
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center">
                  {loadingText !== "DONE" && (
                    <div className="w-6 h-6 border-4 border-gray-400 border-t-transparent border-solid rounded-full animate-spin mb-2"></div>
                  )}
                  <span
                    className={`${
                      loadingText === "DONE"
                        ? "text-green-500"
                        : "text-orange-500"
                    }`}
                  >
                    {loadingText}
                  </span>
                </div>
              )}
            </div>
          </div>

          {!loading && (
            <div className="flex justify-end gap-2 p-6 border-t border-gray-200">
              <button
                type="button"
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Create New
              </button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
