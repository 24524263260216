const MagicSvg = () => {
  return (
    <svg
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="-mt-0.5"
    >
      <path
        d="M11.375 6.87497L10.5059 7.74414L8.75586 5.99414L9.62503 5.12497C9.87003 4.87997 10.185 4.76331 10.5 4.76331C10.815 4.76331 11.13 4.87997 11.375 5.12497C11.8592 5.60914 11.8592 6.39081 11.375 6.87497Z"
        fill="#E1FF01"
      />
      <path
        d="M10.0975 8.15833L3.79165 14.4583C3.30749 14.9425 2.52582 14.9425 2.04165 14.4583C1.55749 13.9742 1.55749 13.1925 2.04165 12.7083L8.34749 6.40833L10.0975 8.15833Z"
        fill="#E1FF01"
      />
      <path
        d="M5.8042 4.54168L6.04336 3.73084C6.0667 3.65501 6.04336 3.57334 5.99086 3.51501C5.93836 3.45668 5.84503 3.43334 5.7692 3.45668L4.95836 3.69584L4.14753 3.45668C4.0717 3.43334 3.99003 3.45668 3.9317 3.50918C3.87336 3.56751 3.85586 3.64918 3.8792 3.72501L4.11253 4.54168L3.87336 5.35251C3.85003 5.42834 3.87336 5.51001 3.92586 5.56834C3.9842 5.62668 4.06586 5.64418 4.1417 5.62084L4.95836 5.38751L5.7692 5.62668C5.79253 5.63251 5.81003 5.63834 5.83336 5.63834C5.8917 5.63834 5.9442 5.61501 5.99086 5.57418C6.0492 5.51584 6.0667 5.43418 6.04336 5.35834L5.8042 4.54168Z"
        fill="#E1FF01"
      />
      <path
        d="M3.47082 8.04168L3.70999 7.23084C3.73332 7.15501 3.70999 7.07334 3.65749 7.01501C3.59916 6.95668 3.51749 6.93918 3.44166 6.96251L2.62499 7.19584L1.81416 6.95668C1.73832 6.93334 1.65666 6.95668 1.59832 7.00918C1.53999 7.06751 1.52249 7.14918 1.54582 7.22501L1.77916 8.04168L1.53999 8.85251C1.51666 8.92834 1.53999 9.01001 1.59249 9.06834C1.65082 9.12668 1.73249 9.14418 1.80832 9.12084L2.61916 8.88168L3.42999 9.12084C3.44749 9.12668 3.47082 9.12668 3.49416 9.12668C3.55249 9.12668 3.60499 9.10334 3.65166 9.06251C3.70999 9.00418 3.72749 8.92251 3.70416 8.84668L3.47082 8.04168Z"
        fill="#E1FF01"
      />
      <path
        d="M12.2209 10.9583L12.46 10.1475C12.4834 10.0716 12.46 9.98997 12.4075 9.93164C12.3492 9.87331 12.2675 9.85581 12.1917 9.87914L11.3809 10.1183L10.57 9.87914C10.4942 9.85581 10.4125 9.87914 10.3542 9.93164C10.2959 9.98997 10.2784 10.0716 10.3017 10.1475L10.5409 10.9583L10.3017 11.7691C10.2784 11.845 10.3017 11.9266 10.3542 11.985C10.4125 12.0433 10.4942 12.0608 10.57 12.0375L11.3809 11.7983L12.1917 12.0375C12.2092 12.0433 12.2325 12.0433 12.2559 12.0433C12.3142 12.0433 12.3667 12.02 12.4134 11.9791C12.4717 11.9208 12.4892 11.8391 12.4659 11.7633L12.2209 10.9583Z"
        fill="#E1FF01"
      />
    </svg>
  );
};

export default MagicSvg;
