import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import Input from "../Input";
import Checkbox from "../Checkbox";
import { loginService } from "../../../service/auth";

const Login = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      username: Yup.string().required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      setError(null);
      try {
        const response = await loginService(values);
        const auth_key = localStorage.getItem("auth_key");
        if (response && auth_key) {
          navigate("/");
        }
      } catch (error) {
        setError(error?.response?.data?.detail);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div className="flex justify-center items-center w-full p-8">
      <form
        onSubmit={formik.handleSubmit}
        className="bg-white shadow-md w-full lg:w-[500px] p-10 lg:p-[52px] space-y-8"
      >
        <div className="text-center space-y-3 lg:space-y-4">
          <h1 className="text-4xl lg:text-5xl font-normal text-black">Login</h1>
          <p className="text-sm lg:text-base font-normal text-gray-500">
            Welcome back! Please enter your details
          </p>
        </div>
        <div>
          <Input
            id="username"
            label="Username"
            type="text"
            name="username"
            placeholder="Enter username"
            required={true}
            formik={formik}
          />
          <div className="h-6"></div>
          <Input
            id="password"
            label="Password"
            type="password"
            name="password"
            placeholder="Enter Password"
            required={true}
            formik={formik}
          />
          <div className="h-4"></div>
          <div className="flex items-center justify-between">
            <Checkbox
              id="remember"
              label="Remember Me"
              name="remember"
              required={false}
            />
            <a
              href="mailto:info@intriq.ai"
              className="text-sm font-medium text-tosca"
            >
              Forgot Password? Contact Support
            </a>
          </div>
          <div className="h-4"></div>
          {error && <p className="text-red-600 text-sm font-normal">{error}</p>}
        </div>
        <div className="space-y-4">
          {!loading ? (
            <button
              type="submit"
              className="w-full mt-2 bg-tosca rounded-md py-3 text-black text-base font-medium"
            >
              Login
            </button>
          ) : (
            <button
              type="button"
              className="w-full mt-2 bg-bgbase rounded-md py-3 text-secondary text-base font-medium"
            >
              Login
            </button>
          )}
          <div className="text-center text-sm font-normal text-gray-500">
            Don’t have an account?
            <span>
              <button
                onClick={props.handleRegister}
                className="text-sm font-medium text-tosca ml-1"
              >
                Register
              </button>
            </span>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
