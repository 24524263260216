const UploadSvg = ({ size = "12" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.21999 4.45C10.02 4.605 10.755 5.53 10.755 7.555V7.62C10.755 9.855 9.85999 10.75 7.62499 10.75H4.36999C2.13499 10.75 1.23999 9.855 1.23999 7.62V7.555C1.23999 5.545 1.96499 4.62 3.73499 4.455"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 7.5V1.81"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.67501 2.925L6.00001 1.25L4.32501 2.925"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default UploadSvg;
