import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

// SVG Icons
import HomeSvg from "../../icons/HomeSvg";
import ClockSvg from "../../icons/ClockSvg";
import EyeSvg from "../../icons/EyeSvg";
import SearchSvg from "../../icons/SearchSvg";

// Components and Utilities
import BubleChat from "../../components/BubleChat";
import { renderDocTypeChat } from "./data";
import { chatService } from "../../service/chat";
import ExpandableInput from "../../components/ExpandableInput";

// Skeleton Loading Component
const ChatSkeleton = () => (
  <div className="animate-pulse flex space-x-4 mb-4">
    <div className="flex-1 space-y-4 py-1">
      <div className="h-4 bg-gray-300 rounded w-2/4"></div>
      <div className="h-4 bg-gray-300 rounded w-4/6"></div>
    </div>
  </div>
);

const ChatPage = () => {
  // Location and URL Parameters
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const date = queryParams.get("date");
  const pathSegments = location.pathname.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];

  // State Management
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isChatStarted, setIsChatStarted] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [pendingUserMessage, setPendingUserMessage] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState(""); // Add search keyword state

  // Refs
  const messagesEndRef = useRef(null);

  // Scroll to Bottom Function
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Effect for Scrolling
  useEffect(() => {
    scrollToBottom();
  }, [messages, pendingUserMessage]);

  // Message Send Handler
  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (!inputValue.trim()) return;

    setIsChatStarted(true);

    if (!startDate) setStartDate(new Date().toLocaleString());

    // Optimistically add user message
    const userMessage = { position: "right", text: inputValue };
    setPendingUserMessage(userMessage);
    setInputValue("");

    try {
      const responseMessage = await chatService(inputValue);
      const aiText = responseMessage?.output;

      // Add user message and AI response
      const aiResponse = {
        position: "left",
        text: aiText,
      };

      setMessages((prevMessages) => [...prevMessages, userMessage, aiResponse]);
      setPendingUserMessage(null);
    } catch (error) {
      const errorResponse = {
        position: "left",
        text: "Sorry, I couldn't answer your question. Please try again later.",
      };

      setMessages((prevMessages) => [
        ...prevMessages,
        userMessage,
        errorResponse,
      ]);
      setPendingUserMessage(null);

      console.error("Error sending message:", error);
    }
  };

  // Function to highlight search keyword
  const highlightText = (text, keyword) => {
    if (!keyword) return text;

    const parts = text.split(new RegExp(`(${keyword})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === keyword.toLowerCase() ? (
        <span key={index} className="bg-yellow-300">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  // Filter messages based on search keyword
  const filteredMessages = searchKeyword
    ? messages.map((message) => ({
        ...message,
        text: highlightText(message.text, searchKeyword),
      }))
    : messages;

  return (
    <>
      {/* Header Section */}
      <div className="text-xl flex gap-2 z-50">
        {/* Home Icon */}
        <div className="bg-blue-200 text-blue-500 p-1 rounded-md mr-2">
          <HomeSvg size={18} />
        </div>

        {lastSegment}

        {/* View Document */}
        <div className="flex gap-2">
          <div className="my-auto font-productSans rounded bg-[#F1F6FE] py-1 px-2 !text-xs flex gap-1">
            <span className="m-auto text-gray-400">
              <EyeSvg />
            </span>
            <b>View Document</b>
          </div>
        </div>

        {/* Document Type */}
        <div className="flex gap-2">{renderDocTypeChat(type)}</div>

        {/* Date */}
        <div className="flex gap-2">
          <div className="my-auto font-productSans border rounded border-gray-400 py-1 px-2 !text-xs flex gap-1">
            <span className="m-auto text-gray-400">
              <ClockSvg />
            </span>
            <b>{date}</b>
          </div>
        </div>

        {/* Search Input */}
        <div className="absolute right-4 -mt-1.5">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <SearchSvg className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            className="pl-10 pr-3 py-2 w-[300px] bg-[#F9FBFF] text-sm text-primary border border-[#D6E0E7] rounded-[4px] 
            hover:border-blue-300 focus:border-blue-300 focus:outline-none"
            placeholder="Search keyword"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
        </div>
      </div>

      {/* Divider */}
      <hr className="mt-3 -mx-4" />

      {/* Chat Container */}
      <div className="h-[calc(100vh-10rem)] flex flex-col justify-center items-center">
        {!isChatStarted ? (
          // Initial Chat Start UI
          <div className="w-[60%] text-center">
            <h2 className="text-2xl font-semibold mb-4 flex gap-2 justify-center">
              <span>
                <img
                  src="/images/star.png"
                  alt="Avatar pengguna"
                  className="w-6 h-6"
                />
              </span>
              What can I help with?
            </h2>
            <form onSubmit={handleSendMessage} className="w-full">
              <div className="relative">
                <ExpandableInput
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onSend={handleSendMessage}
                  placeholder="Message Assistant"
                />
              </div>
            </form>
          </div>
        ) : (
          // Active Chat UI
          <div className="w-[60%] flex flex-col justify-between h-full">
            <div className="overflow-y-auto flex-1 mb-4 px-3">
              {/* Date Separator */}
              <div className="relative flex py-5 items-center">
                <div className="flex-grow border-t border-[#D6E0E7]"></div>
                <span className="flex-shrink mx-4 border-[#D6E0E7] text-secondary">
                  {startDate}
                </span>
                <div className="flex-grow border-t border-[#D6E0E7]"></div>
              </div>

              {/* Filtered and Highlighted Messages */}
              {filteredMessages.map((message, index) => (
                <BubleChat
                  key={index}
                  position={message.position}
                  text={message.text}
                />
              ))}

              {/* Optimistic Loading Pending Message */}
              {pendingUserMessage && (
                <>
                  <BubleChat
                    position={pendingUserMessage.position}
                    text={pendingUserMessage.text}
                  />
                  <ChatSkeleton />
                </>
              )}

              {/* Scroll Reference */}
              <div ref={messagesEndRef} />
            </div>

            {/* Message Input */}
            <form onSubmit={handleSendMessage}>
              <div className="relative">
                <ExpandableInput
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onSend={handleSendMessage}
                  placeholder="Message Assistant"
                />
              </div>
            </form>
          </div>
        )}

        {/* Footer */}
        <div className="absolute bottom-4 text-secondary text-center">
          <b>© 2024, Intriq.</b> All Rights Reserved.
        </div>
      </div>
    </>
  );
};

export default ChatPage;
