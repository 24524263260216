const SearchSvg = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.70817 12.25C9.76875 12.25 12.2498 9.76891 12.2498 6.70833C12.2498 3.64775 9.76875 1.16666 6.70817 1.16666C3.64759 1.16666 1.1665 3.64775 1.1665 6.70833C1.1665 9.76891 3.64759 12.25 6.70817 12.25Z"
        stroke="#2A2E33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.8332 12.8333L11.6665 11.6667"
        stroke="#2A2E33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SearchSvg;
